import { isForm, mapFormToObject } from '@/helpers/dom';
import { ClientTemplate, Component } from '@/components/ClientTemplate';
import {
  Pages, UseResetPasswordCodePageTemplate, PasswordRequestPayload, ErrorResponse,
} from '@/types';

@Component
export default class ClientResetPassword extends ClientTemplate<Pages.UseResetPasswordCode> {
  passwordIsHidden = true;

  confirmPasswordIsHidden = true;

  successfullySet = false;

  get code() {
    return this.store.getResetPasswordCode();
  }

  get lang() {
    return this.i18n.getLang();
  }

  validatePassword(payload: PasswordRequestPayload): ErrorResponse | null {
    if (payload.password !== payload.confirm_password) {
      return { messages: [this.i18n.translate('passwordMismatch')] };
    }
    return null;
  }

  async resetPassword(element: Element) {
    this.error = null;

    if (isForm(element)) {
      const payload = mapFormToObject<PasswordRequestPayload>(element);

      if ('confirm_password' in payload) {
        console.log(payload);
        this.error = this.validatePassword(payload);
        if (this.error) return;
      }

      const result = await this.pds.useResetPasswordCode({
        code: this.code,
        password: payload.password,
        lang: this.lang,
      });

      if (result.isFailure()) {
        this.error = result.error;
      } else {
        this.successfullySet = true;
      }
    }
  }

  build() {
    const data: UseResetPasswordCodePageTemplate = {
      successfullySet: this.successfullySet,
      resetPasswordLink: this.actions.create((element) => {
        this.resetPassword(element);
      }),
      passwordIsHidden: this.passwordIsHidden,
      showPasswordLink: this.actions.create(() => {
        this.passwordIsHidden = !this.passwordIsHidden;
      }),
      confirmPasswordIsHidden: this.confirmPasswordIsHidden,
      showConfirmPasswordLink: this.actions.create(() => {
        this.confirmPasswordIsHidden = !this.confirmPasswordIsHidden;
      }),
    };
    return [Pages.UseResetPasswordCode, data] as const;
  }
}
